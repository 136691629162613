import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { styled } from "@mui/material/styles";
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Switch, Tab, Tabs, TextField, Tooltip, tooltipClasses, useMediaQuery, useTheme, } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useParams } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { addTeacherCourseAsync, enableDisableTeacherCourseAsync, getTeacherCoursesAsync, updateTeacherCourseAsync, deleteTeacherCourseAsync, enableDisableTeacherAnnualPlanCourseAsync, } from "@application/features/teacher/teacherThunk";
import { setIsCourseLoaded, setTeacherCourses, setTeacherRequest, toggleIframeModelOpen, toggleIframeModelTabOpen, } from "@application/features/teacher/teacherSlice";
import { setFeaturedImage } from "@application/features/theme/themeSlice";
import { CourseItemType } from "@application/common/enums/course-item-type";
//import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { Icon } from "@iconify/react";
import { CourseItemCategory, CourseItemCategoryStatusMap, } from "@application/common/enums/course-item-category";
import { Edit, Info, Delete } from "@mui/icons-material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WnValidation from "@ui/components/wn-validation";
import { CourseInstructionCategory, CourseInstructionCategoryStatusMap, } from "@application/common/enums/course-instruction-category";
import Handleiding from "../../../../public/images/handleiding.png";
import Knutsel from "../../../../public/images/knutsel.png";
import Leesblad from "../../../../public/images/leesblad.png";
import Werkblad from "../../../../public/images/werkblad.png";
import KnutselWhite from "../../../../public/images/craft.png";
import Alphabet from "../../../../public/images/abcicon.png";
import Calculate from "../../../../public/images/123icon.png";
import Video from "../../../../public/images/video.svg";
import Audio from "../../../../public/images/music.svg";
import Picture from "../../../../public/images/picture.svg";
import Miscallenious from "../../../../public/images/misc.svg";
import { APIStatus } from "@application/common/enums/api-status";
import WnPdfViewerTwo from "@ui/components/wn-pdf-viewer-two";
const popupTabStyles = {
    minHeight: "unset",
    textTransform: "unset",
};
const Teacher = () => {
    const dispatch = useAppDispatch();
    const { register, setValue, control, reset, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            name: "",
            link: "",
            courseId: "",
            courseItemCategory: undefined,
        },
    });
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [tabValue, setTabValue] = useState(0);
    const [teacherCourse, setTeacherCourse] = useState(undefined);
    const handleTabClick = (tabValue) => {
        let selectedInstruction = teacherCourse?.courseInstructions.filter((x) => Number(x.courseInstructionCategory) == tabValue)[0];
        if (storageUrl && selectedInstruction) {
            setLink(storageUrl.replace("fileShoulebeReplace", selectedInstruction.link));
        }
    };
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }
    const { themeList } = useAppSelector((state) => state.theme);
    const { teacherCourses, teacherRequest, iframeModelOpen, iframeModelTabOpen, status, } = useAppSelector((state) => state.teacher);
    const { seasonId, themeId } = useParams();
    const storageUrl = process.env.STORAGE_ACCOUNT;
    const [isEditing, setIsEditing] = useState(false);
    const [addCourseOpen, setAddCourseOpen] = useState(false);
    const [embedlink, setLink] = useState("");
    const [urlType, setUrlType] = useState(undefined);
    const handleIncludeInAnnualPlanChange = ($event, id) => {
        if (id) {
            onEnableDisableTeacherCourseAnnualPlan($event, id);
        }
    };
    const handleEnableDisableChange = ($event, id) => {
        if (id) {
            onEnableDisable($event, id);
        }
    };
    const handleAddCourseClick = () => {
        setSelectedItem(undefined);
        setAddCourseOpen(true);
    };
    const courseCategoryTypeList = [
        {
            id: Number(CourseItemCategory.Video).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.Video),
        },
        {
            id: Number(CourseItemCategory.Audio).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.Audio),
        },
        {
            id: Number(CourseItemCategory.Picture).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.Picture),
        },
        {
            id: Number(CourseItemCategory.Miscellaneous).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.Miscellaneous),
        },
        {
            id: Number(CourseItemCategory.ReadingSheet).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.ReadingSheet),
        },
        {
            id: Number(CourseItemCategory.WorkSheet).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.WorkSheet),
        },
        {
            id: Number(CourseItemCategory.Craft).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.Craft),
        },
        {
            id: Number(CourseItemCategory.Language).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.Language),
        },
        {
            id: Number(CourseItemCategory.Calculate).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.Calculate),
        },
    ];
    const handleClickOpenIfram = (courseItemDto) => {
        if (courseItemDto.courseItemType === CourseItemType.UploadAttachment &&
            storageUrl) {
            setUrlType(CourseItemType.UploadAttachment);
            setLink(storageUrl.replace("fileShoulebeReplace", courseItemDto.link));
        }
        else {
            setUrlType(CourseItemType.ExternalLink);
            setLink(courseItemDto.link);
        }
        dispatch(toggleIframeModelOpen());
    };
    const handleCourseInstructionOpenIframe = (teacherCourseDto) => {
        setTeacherCourse(teacherCourseDto);
        let courseInstruction = teacherCourseDto.courseInstructions[0];
        if (storageUrl) {
            setLink(storageUrl.replace("fileShoulebeReplace", courseInstruction.link));
        }
        dispatch(toggleIframeModelTabOpen());
    };
    const handleIframeClose = () => {
        setLink("");
        setUrlType(undefined);
        dispatch(toggleIframeModelOpen());
        setAnchorEl(null);
    };
    const handleIframeTabClose = () => {
        setLink("");
        dispatch(toggleIframeModelTabOpen());
    };
    const handleAddCourseClose = () => {
        setAddCourseOpen(false);
        setValue("name", "");
        setValue("link", "");
        setValue("courseId", "");
        handleReset();
    };
    useEffect(() => {
        if (seasonId && themeId) {
            dispatch(setTeacherRequest({ seasonId, themeId }));
        }
    }, [dispatch, seasonId, themeId]);
    useEffect(() => {
        dispatch(setIsCourseLoaded(true));
        dispatch(setTeacherCourses([]));
        if (teacherRequest) {
            dispatch(getTeacherCoursesAsync(teacherRequest));
        }
    }, [dispatch, teacherRequest]);
    useEffect(() => {
        if (themeList && themeList.length && themeId) {
            const result = themeList.find((x) => x.id === themeId)?.featuredImage;
            dispatch(setFeaturedImage(result ?? ""));
        }
    }, [dispatch, themeList, themeList.length, themeId]);
    const validateCourseCategory = (courseCategory) => {
        if (courseCategory === undefined)
            return "Categorie is vereist.";
        const allowedTypes = [
            CourseItemCategory.Video,
            CourseItemCategory.Audio,
            CourseItemCategory.Picture,
            CourseItemCategory.Miscellaneous,
            CourseItemCategory.ReadingSheet,
            CourseItemCategory.WorkSheet,
            CourseItemCategory.Craft,
            CourseItemCategory.Language,
            CourseItemCategory.Calculate,
        ];
        if (!allowedTypes.includes(courseCategory)) {
            return "Categorie is vereist.";
        }
        return true;
    };
    const onSubmit = (credentials) => {
        if (status === APIStatus.PENDING)
            return;
        if (!isEditing) {
            const dto = {
                name: credentials?.name,
                courseId: credentials?.courseId,
                courseItemCategory: credentials?.courseItemCategory,
                link: credentials?.link,
            };
            dispatch(addTeacherCourseAsync(dto)).then((res) => {
                if (res.payload === true) {
                    handleAddCourseClose();
                    if (teacherRequest) {
                        dispatch(getTeacherCoursesAsync(teacherRequest));
                    }
                }
            });
        }
        else {
            const dto = {
                name: credentials?.name,
                courseId: credentials?.courseId,
                courseItemCategory: credentials?.courseItemCategory,
                link: credentials?.link,
            };
            dispatch(updateTeacherCourseAsync(dto)).then((res) => {
                if (res.payload === true) {
                    handleAddCourseClose();
                    if (teacherRequest) {
                        dispatch(getTeacherCoursesAsync(teacherRequest));
                    }
                }
            });
            setIsEditing(false);
        }
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openEditDelete = Boolean(anchorEl);
    const handleClick = (event, item) => {
        setSelectedItem(item);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleView = (event) => {
        handleClickOpenIfram(selectedItem);
    };
    const handleEdit = (event) => {
        setIsEditing(true);
        setAddCourseOpen(true);
        if (selectedItem) {
            setValue("courseItemCategory", selectedItem.courseItemCategory);
            setValue("name", selectedItem?.name || "");
            setValue("link", selectedItem?.link || "");
            setValue("courseId", selectedItem?.id || "");
        }
    };
    const handleDelete = async (event) => {
        if (selectedItem) {
            dispatch(deleteTeacherCourseAsync(selectedItem.id)).then((res) => {
                if (res.payload == true) {
                    if (teacherRequest) {
                        dispatch(getTeacherCoursesAsync(teacherRequest));
                        handleClose();
                    }
                }
            });
        }
    };
    const theme = useTheme();
    const onEnableDisable = ($event, id) => {
        dispatch(enableDisableTeacherCourseAsync({
            courseId: id,
            status: $event.target.checked,
        })).then((res) => {
            if (res.payload === true) {
                if (teacherRequest) {
                    dispatch(getTeacherCoursesAsync(teacherRequest));
                }
            }
        });
    };
    const onEnableDisableTeacherCourseAnnualPlan = ($event, id) => {
        dispatch(enableDisableTeacherAnnualPlanCourseAsync({
            courseId: id,
            status: $event.target.checked,
        })).then((res) => {
            if (res.payload === true) {
                if (teacherRequest) {
                    dispatch(getTeacherCoursesAsync(teacherRequest));
                }
            }
        });
    };
    const handleReset = () => {
        reset();
    };
    const getTabIcon = (courseInstructionCategory) => {
        let result = "";
        if (courseInstructionCategory === CourseInstructionCategory.Instruction) {
            result = Handleiding;
        }
        else if (courseInstructionCategory === CourseInstructionCategory.ReadingSheet) {
            result = Leesblad;
        }
        else if (courseInstructionCategory === CourseInstructionCategory.WorkSheet) {
            result = Werkblad;
        }
        else if (courseInstructionCategory === CourseInstructionCategory.Craft) {
            result = Knutsel;
        }
        else if (courseInstructionCategory === CourseInstructionCategory.Language) {
            result = Alphabet;
        }
        else if (courseInstructionCategory === CourseInstructionCategory.Calculate) {
            result = Calculate;
        }
        else if (courseInstructionCategory === CourseInstructionCategory.Video) {
            result = Video;
        }
        else if (courseInstructionCategory === CourseInstructionCategory.Picture) {
            result = Picture;
        }
        else if (courseInstructionCategory === CourseInstructionCategory.Audio) {
            result = Audio;
        }
        else if (courseInstructionCategory === CourseInstructionCategory.Miscellaneous) {
            result = Miscallenious;
        }
        return result;
    };
    const getCourseItemIcon = (courseitemCategory) => {
        let result = "";
        if (courseitemCategory === CourseItemCategory.Video) {
            result = "solar:videocamera-record-bold";
        }
        else if (courseitemCategory === CourseItemCategory.Audio) {
            result = "solar:music-note-bold";
        }
        else if (courseitemCategory === CourseItemCategory.Picture) {
            result = "solar:camera-bold";
        }
        else if (courseitemCategory === CourseItemCategory.Miscellaneous) {
            result = "fa6-solid:question";
        }
        else if (courseitemCategory === CourseItemCategory.ReadingSheet) {
            result = "bi:eyeglasses";
        }
        else if (courseitemCategory === CourseItemCategory.WorkSheet) {
            result = "mingcute:pen-2-line";
        }
        else if (courseitemCategory === CourseItemCategory.Language) {
            result = "mdi:alphabetical-variant";
        }
        else if (courseitemCategory === CourseItemCategory.Calculate) {
            result = "mdi:123";
        }
        else if (courseitemCategory === CourseItemCategory.Craft) {
            result = KnutselWhite;
        }
        return result;
    };
    const getCourseItemColour = (courseitemCategory) => {
        let result = "";
        if (courseitemCategory === CourseItemCategory.Video ||
            courseitemCategory === CourseItemCategory.Audio ||
            courseitemCategory === CourseItemCategory.Picture ||
            courseitemCategory === CourseItemCategory.Miscellaneous) {
            result = "#2d8ac5";
        }
        else if (courseitemCategory === CourseItemCategory.ReadingSheet ||
            courseitemCategory === CourseItemCategory.WorkSheet ||
            courseitemCategory === CourseItemCategory.Craft) {
            result = "#48B848";
        }
        else if (courseitemCategory === CourseItemCategory.Language ||
            courseitemCategory == CourseItemCategory.Calculate) {
            result = "#ff3131";
        }
        else {
            result = "";
        }
        return result;
    };
    const BootstrapTooltip = styled(({ className, ...props }) => (_jsx(Tooltip, { ...props, arrow: true, classes: { popper: className } })))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:768px)");
    const lessIpad = useMediaQuery("(min-width:100px) and (max-width:1025px)");
    const [orientation, setOrientation] = useState("portrait");
    // const handleResize = () => {
    //   setOrientation(
    //     window.innerWidth > window.innerHeight ? "landscape" : "portrait"
    //   );
    //   dispatch(closeIframeModelOpen())
    // };
    // useEffect(() => {
    //   handleResize(); // Check on initial render
    //   window.addEventListener("resize", handleResize);
    //   return () => {
    //     window.removeEventListener("resize", handleResize);
    //   };
    // }, []);
    return (_jsxs(Box, { children: [_jsxs(Grid, { container: true, mt: { xs: 5, sm: 5, md: 0 }, spacing: 4, sx: { backgroundColor: "#fff", height: "100%", marginTop: "0" }, children: [_jsx(Grid, { item: true, xs: 1 }), _jsx(Grid, { item: true, xs: 11, children: _jsx(Grid, { container: true, maxWidth: "lg", spacing: 4, children: teacherCourses?.map((course) => (_jsx(Grid, { item: true, xl: 4, lg: 6, xs: 12, children: _jsxs(Box, { sx: { display: "flex", gap: "20px" }, className: "Lesson", children: [_jsx("img", { src: storageUrl?.replace("fileShoulebeReplace", course?.featuredImage), className: "lesson-image", alt: "" }, `img-${course.id}`), _jsxs(Box, { sx: {
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "12px",
                                                flex: "1 1 auto",
                                            }, children: [_jsxs(Box, { sx: { pr: { xs: "12%", lg: 0 } }, children: [_jsxs(Box, { sx: {
                                                                display: "flex",
                                                                gap: "4px",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }, children: [_jsx("h6", { className: "lesson-title", children: course?.name }, `title-${course.id}`), _jsx(Box, { sx: { display: "flex", alignItems: "center", gap: 1 }, children: course?.courseInstructions &&
                                                                        course.courseInstructions.length > 0 && (_jsx(Box, { sx: {
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: 1,
                                                                        }, children: _jsx("img", { src: "images/kids/info_icon.png", width: 32, style: { cursor: "pointer" }, alt: "info", onClick: () => handleCourseInstructionOpenIframe(course) }) })) })] }, `box-2-${course.id}`), _jsx(Box, { children: _jsxs(Grid, { item: true, container: true, flexWrap: "wrap", spacing: 1, children: [_jsx(Grid, { item: true, xs: "auto", children: course.isActive && (_jsx(FormControlLabel, { control: _jsx(Switch, { size: "small", checked: course.isActiveByTeacher, onChange: ($event) => handleEnableDisableChange($event, course.id) }, `enableDisable-switch-${course.id}`), label: "Zichtbaar" }, `enableDisable-${course.id}`)) }), _jsx(Grid, { item: true, xs: "auto", children: _jsx(FormControlLabel, { control: _jsx(Switch, { size: "small", checked: course.isCourseIncludedinAnnualPlan, onChange: ($event) => handleIncludeInAnnualPlanChange($event, course.id) }, `includeInAnnualPlan-switch-${course.id}`), label: "Jaarplan" }, `includeInAnnualPlan-${course.id}`) })] }) })] }), _jsxs(Box, { sx: {
                                                        display: "flex",
                                                        gap: "12px",
                                                        flexWrap: "wrap",
                                                    }, children: [course?.courseItems?.map((item, index) => (_jsx("button", { className: "lesson-btn", children: _jsx("div", { className: "lesson-icon-container", style: {
                                                                    height: 36,
                                                                    width: 36,
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    backgroundColor: getCourseItemColour(item.courseItemCategory),
                                                                    borderRadius: 50,
                                                                    cursor: "pointer",
                                                                }, onClick: () => handleClickOpenIfram(item), children: _jsx(BootstrapTooltip, { title: item.description, placement: "top", children: item.courseItemCategory ===
                                                                        CourseItemCategory.Craft ? (_jsx("img", { src: getCourseItemIcon(item.courseItemCategory), width: 24, height: 24, alt: item.courseItemCategory.toString() })) : (_jsx(Icon, { icon: getCourseItemIcon(item.courseItemCategory), fontSize: 24, color: "#fff" })) }) }) }, `btn-${item.id}`))), course?.teacherCourseItems &&
                                                            course?.teacherCourseItems?.map((item, index) => (_jsx("button", { className: "lesson-btn", children: _jsx("div", { className: "lesson-icon-container", style: {
                                                                        height: 36,
                                                                        width: 36,
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        backgroundColor: "#f68735",
                                                                        borderRadius: 50,
                                                                        cursor: "pointer",
                                                                    }, onClick: (event) => handleClick(event, item), children: _jsx(BootstrapTooltip, { title: item.name, placement: "top", children: item.courseItemCategory ===
                                                                            CourseItemCategory.Craft ? (_jsx("img", { src: getCourseItemIcon(item.courseItemCategory), width: 24, height: 24, alt: item.courseItemCategory.toString() })) : (_jsx(Icon, { icon: getCourseItemIcon(item.courseItemCategory), fontSize: 24, color: "#fff" })) }) }) }, `btn-${item.id}`))), course.teacherCourseItems.length < 3 && (_jsx("button", { className: "lesson-btn add", onClick: () => {
                                                                setValue("courseId", course.id);
                                                                handleAddCourseClick();
                                                            }, children: _jsx("div", { className: "lesson-icon-container", style: {
                                                                    height: 36,
                                                                    width: 36,
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    backgroundColor: "transparent",
                                                                    color: "#f68735",
                                                                    border: "1px solid #f68735",
                                                                    borderRadius: 50,
                                                                    cursor: "pointer",
                                                                }, children: _jsx(Icon, { icon: "fluent:add-16-filled", fontSize: 24, color: "#f68735" }) }) }, `btn-add-${course.id}`))] }, `course-item-box-${course.id}`)] }, `box-1-${course.id}`)] }, `box-${course.id}`) }, `grid-${course.id}`))) }) })] }), openEditDelete && (_jsxs(Menu, { id: "demo-positioned-menu", "aria-labelledby": "demo-positioned-button", anchorEl: anchorEl, open: openEditDelete, onClose: handleClose, children: [_jsxs(MenuItem, { onClick: handleView, children: [_jsx(ListItemIcon, { children: _jsx(Info, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Weergave" })] }), _jsxs(MenuItem, { onClick: handleEdit, children: [_jsx(ListItemIcon, { children: _jsx(Edit, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Bewerking" })] }), _jsx(Divider, {}), _jsxs(MenuItem, { onClick: handleDelete, children: [_jsx(ListItemIcon, { children: _jsx(Delete, { fontSize: "small", color: "error" }) }), _jsx(ListItemText, { sx: { color: theme.palette.error.main }, children: "Verwijderen" })] })] })), _jsxs(Dialog, { fullWidth: true, open: iframeModelOpen, onClose: handleIframeClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", sx: {
                    "& .MuiDialog-paper": {
                        padding: isSmall ? "0px" : "5px",
                        maxWidth: "100%",
                    },
                }, children: [_jsxs(DialogTitle, { id: "alert-dialog-title", sx: {
                            color: "primary.main",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: isSmall ? "4px" : "6px 14px",
                            pb: isSmall ? "0px" : "",
                        }, children: ["\u00A0", _jsx(Button, { variant: "outlined", color: "primary", size: isSmall ? "small" : "medium", onClick: handleIframeClose, style: { padding: isSmall ? "0x 0px" : "" }, sx: {
                                    boxShadow: "-1.5px 1.5px 0 #F68735",
                                    color: "customDark.main",
                                    padding: "1.25px 14px",
                                    borderRadius: "6px",
                                    minWidth: "auto",
                                }, children: "X" })] }), _jsx(DialogContent, { sx: {
                            padding: isSmall ? "0px !important" : "0px 13px !important",
                            height: "auto !important",
                        }, children: _jsx("div", { children: urlType && urlType === CourseItemType.UploadAttachment ? (
                            // <DocViewer
                            //   style={{
                            //     height: lessIpad ? " 100%" : `${window.innerHeight - 140}`,
                            //   }}
                            //   className="customName"
                            //   theme={{
                            //     disableThemeScrollbar: true,
                            //   }}
                            //   config={{
                            //     header: {
                            //       overrideComponent: undefined,
                            //     },
                            //   }}
                            //   documents={[{ uri: embedlink }]}
                            //   pluginRenderers={DocViewerRenderers}
                            // />
                            _jsx(WnPdfViewerTwo, { pdfUrl: embedlink, isSmall: isSmall, windowHeight: window.innerHeight })
                            // <WnPdfViewer pdfUrl={embedlink} isSmall={isSmall} windowHeight={window.innerHeight} />
                            ) : (_jsx("iframe", { title: "course", src: embedlink, width: "100%", height: isSmall
                                    ? `${window.innerHeight - 490}`
                                    : `${window.innerHeight - 140}`, allowFullScreen: true, style: { borderRadius: "20px" } })) }) })] }), _jsxs(Dialog, { fullWidth: true, open: iframeModelTabOpen, onClose: handleIframeTabClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", sx: {
                    "& .MuiDialog-paper": {
                        padding: "5px",
                        maxWidth: "100%",
                    },
                }, children: [_jsxs(DialogTitle, { id: "alert-dialog-title", sx: {
                            color: "primary.main",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "6px 14px",
                        }, children: [_jsx(Box, { sx: {
                                    overflowX: "auto",
                                    whiteSpace: "nowrap",
                                    flexGrow: 1,
                                    borderBottom: 1,
                                    borderColor: "divider",
                                    "&::-webkit-scrollbar": {
                                        display: "none",
                                    },
                                    scrollbarWidth: "none",
                                }, children: _jsx(Tabs, { value: tabValue, onChange: handleChange, "aria-label": "basic tabs example", sx: { minWidth: "max-content" }, children: teacherCourse?.courseInstructions.map((courseInstruction, i) => {
                                        return (_jsx(Tab, { onClick: () => handleTabClick(courseInstruction.courseInstructionCategory), sx: popupTabStyles, ...a11yProps(0), icon: _jsx("img", { src: getTabIcon(courseInstruction.courseInstructionCategory), width: 24, height: 24, alt: courseInstruction.courseInstructionCategory.toString() }), iconPosition: "start", label: CourseInstructionCategoryStatusMap.get(courseInstruction.courseInstructionCategory), ...a11yProps(i) }, courseInstruction.courseInstructionCategory));
                                    }) }) }), _jsx(Button, { variant: "outlined", color: "primary", onClick: handleIframeTabClose, sx: {
                                    boxShadow: "-1.5px 1.5px 0 #F68735",
                                    color: "customDark.main",
                                    padding: "1.25px 14px",
                                    borderRadius: "6px",
                                    minWidth: "auto",
                                }, children: "X" })] }), _jsx(DialogContent, { sx: {
                            padding: "0px 13px !important",
                            paddingBottom: "13px !important",
                        }, children: _jsx("div", { children: _jsx("iframe", { title: "course", src: embedlink, width: "100%", height: `${window.innerHeight - 140}`, allowFullScreen: true, style: { borderRadius: "20px" } }) }) })] }), _jsx(Dialog, { fullWidth: true, open: addCourseOpen, onClose: handleAddCourseClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), id: "teacher-add-course-form", noValidate: true, children: [_jsx(DialogTitle, { id: "alert-dialog-title", sx: { color: "primary.main" }, children: "Link naar cursus toevoegen" }), _jsx(DialogContent, { children: _jsxs(Grid, { container: true, spacing: 4, maxWidth: "small", marginTop: 0, children: [_jsxs(Grid, { item: true, xs: 12, children: [_jsx(Controller, { name: `courseItemCategory`, control: control, rules: {
                                                    required: {
                                                        value: true,
                                                        message: "Categorie is vereist.",
                                                    },
                                                    validate: (courseItemCategory) => validateCourseCategory(courseItemCategory),
                                                }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { filterSelectedOptions: true, options: courseCategoryTypeList, value: courseCategoryTypeList.find((option) => option.id === value?.toString()) || null, isOptionEqualToValue: (option, value) => option.id === value.id, onChange: (_, value) => {
                                                        onChange(Number(value?.id ?? undefined));
                                                    }, renderInput: (params) => (_jsx(TextField, { ...params, sx: { borderWidth: "4px" }, InputLabelProps: { children: "" }, label: "Categorie cursusitem", placeholder: "Categorie cursusitem", error: !!errors.courseItemCategory })) })) }), _jsx(WnValidation, { fieldError: errors.courseItemCategory })] }), _jsx(Grid, { item: true, xs: 12, children: _jsx(TextField, { fullWidth: true, label: "Titel", id: "title", placeholder: "e.g. song", sx: { borderWidth: "4px" }, error: !!errors.name, helperText: errors.name?.message, ...register("name", {
                                                required: {
                                                    value: true,
                                                    message: "De naam is vereist.",
                                                },
                                                maxLength: {
                                                    value: 12,
                                                    message: "Er zijn maximaal 12 tekens toegestaan.",
                                                },
                                            }) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(TextField, { fullWidth: true, label: "Canva-link", id: "canvalink", placeholder: "www.canvalink.com", error: !!errors.link, helperText: errors.link?.message, ...register("link", {
                                                required: {
                                                    value: true,
                                                    message: "Koppeling is vereist.",
                                                },
                                                pattern: {
                                                    value: /^(http|https):\/\/[^ "]+$/,
                                                    message: "Ongeldig URL-formaat",
                                                },
                                            }) }) })] }) }), _jsxs(DialogActions, { children: [_jsx(Button, { variant: "outlined", color: "primary", onClick: handleAddCourseClose, sx: {
                                        boxShadow: "-1.5px 1.5px 0 #F68735",
                                        color: "customDark.main",
                                        padding: "1.25px 14px",
                                        borderRadius: "6px",
                                    }, children: "Annuleren" }), _jsx(Button, { variant: "outlined", color: "primary", type: "submit", disabled: status === APIStatus.PENDING, autoFocus: true, sx: {
                                        boxShadow: "-1.5px 1.5px 0 #F68735",
                                        color: "customDark.main",
                                        padding: "1.25px 14px",
                                        borderRadius: "6px",
                                    }, children: "Indienen" })] })] }) })] }));
};
export default Teacher;
