import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import "./style.scss";
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Tooltip, styled, tooltipClasses, useMediaQuery, } from "@mui/material";
import { Icon } from "@iconify/react";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useParams } from "react-router";
import { setFeaturedImage } from "@application/features/theme/themeSlice";
import { CourseItemType } from "@application/common/enums/course-item-type";
import { setIframeLoading, toggleKidsIframeModelOpen, } from "@application/features/kidCourse/kidCourseSlice";
import { getKidCoursesAsync } from "@application/features/kidCourse/kidCourseThunk";
//import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { CourseItemCategory } from "@application/common/enums/course-item-category";
import { useNavigate } from "react-router-dom";
import KnutselWhite from "../../../../public/images/craft.png";
import WnPdfViewerTwo from "@ui/components/wn-pdf-viewer-two";
const Kid = () => {
    const dispatch = useAppDispatch();
    const { currentUser } = useAppSelector((state) => state.auth);
    const { themeList } = useAppSelector((state) => state.theme);
    const { kidCourses, kidsIframeModelOpen } = useAppSelector((state) => state.kidCourse);
    const { themeId } = useParams();
    const storageUrl = process.env.STORAGE_ACCOUNT;
    const [embedlink, setLink] = useState("");
    const [urlType, setUrlType] = useState(undefined);
    const navigate = useNavigate();
    const handleClickOpenIfram = (courseItemDto) => {
        dispatch(setIframeLoading(true));
        if (courseItemDto.courseItemType === CourseItemType.UploadAttachment &&
            storageUrl) {
            setUrlType(CourseItemType.UploadAttachment);
            setLink(storageUrl.replace("fileShoulebeReplace", courseItemDto.link));
        }
        else {
            setUrlType(CourseItemType.ExternalLink);
            setLink(courseItemDto.link);
        }
        dispatch(toggleKidsIframeModelOpen());
    };
    const handleIframeClose = () => {
        setLink("");
        setUrlType(undefined);
        dispatch(toggleKidsIframeModelOpen());
    };
    useEffect(() => {
        if (themeId && currentUser) {
            const getKidCourseDto = {
                teacherId: currentUser?.id ?? "",
                themeId: themeId ?? "",
            };
            dispatch(getKidCoursesAsync(getKidCourseDto));
        }
    }, [dispatch, themeId, currentUser]);
    useEffect(() => {
        if (kidCourses && kidCourses.length > 0) {
            const currentPathname = window.location.pathname;
            const pathArray = currentPathname.split("/");
            const themeId = kidCourses[0].themeId;
            // Assuming the last segment is the GUID
            const lastGuid = pathArray.pop();
            if (lastGuid) {
                const newGuid = themeId; // Replace with your logic to generate a new GUID
                pathArray.push(newGuid);
                // Construct the new path and replace the URL
                const newPath = pathArray.join("/");
                navigate(newPath, { replace: true });
            }
        }
    }, [kidCourses, kidCourses.length > 0]);
    useEffect(() => {
        if (themeList && themeList.length && themeId) {
            const result = themeList.find((x) => x.id === themeId)?.featuredImage;
            dispatch(setFeaturedImage(result ?? ""));
        }
    }, [dispatch, themeList, themeList.length, themeId]);
    const getCourseItemIcon = (courseitemCategory) => {
        let result = "";
        if (courseitemCategory === CourseItemCategory.Video) {
            result = "solar:videocamera-record-bold";
        }
        else if (courseitemCategory === CourseItemCategory.Audio) {
            result = "solar:music-note-bold";
        }
        else if (courseitemCategory === CourseItemCategory.Picture) {
            result = "solar:camera-bold";
        }
        else if (courseitemCategory === CourseItemCategory.Miscellaneous) {
            result = "fa6-solid:question";
        }
        else if (courseitemCategory === CourseItemCategory.ReadingSheet) {
            result = "bi:eyeglasses";
        }
        else if (courseitemCategory === CourseItemCategory.WorkSheet) {
            result = "mingcute:pen-2-line";
        }
        else if (courseitemCategory === CourseItemCategory.Craft) {
            result = KnutselWhite;
        }
        else if (courseitemCategory === CourseItemCategory.Language) {
            result = "mdi:alphabetical-variant";
        }
        else if (courseitemCategory === CourseItemCategory.Calculate) {
            result = "mdi:123";
        }
        return result;
    };
    const getCourseItemColour = (courseitemCategory) => {
        let result = "";
        if (courseitemCategory === CourseItemCategory.Video ||
            courseitemCategory === CourseItemCategory.Audio ||
            courseitemCategory === CourseItemCategory.Picture ||
            courseitemCategory === CourseItemCategory.Miscellaneous) {
            result = "#2d8ac5";
        }
        else if (courseitemCategory === CourseItemCategory.ReadingSheet ||
            courseitemCategory === CourseItemCategory.WorkSheet ||
            courseitemCategory === CourseItemCategory.Craft) {
            result = "#48B848";
        }
        else if (courseitemCategory === CourseItemCategory.Language ||
            courseitemCategory == CourseItemCategory.Calculate) {
            result = "#ff3131";
        }
        else {
            result = "";
        }
        return result;
    };
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:768px)");
    const lessIpad = useMediaQuery("(min-width:100px) and (max-width:1025px)");
    const BootstrapTooltip = styled(({ className, ...props }) => (_jsx(Tooltip, { ...props, arrow: true, classes: { popper: className } })))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, { container: true, spacing: 4, sx: {
                    backgroundColor: "#fff",
                    height: "100%",
                    marginTop: { xs: 3, sm: 3, md: 0 },
                }, children: [_jsx(Grid, { item: true, xs: 2 }), _jsx(Grid, { item: true, xs: 10, children: _jsx(Grid, { container: true, maxWidth: "lg", spacing: 4, children: kidCourses?.map((course) => course.id != "00000000-0000-0000-0000-000000000000" &&
                                course.isActiveByTeacher && (_jsx(Grid, { item: true, xl: 4, lg: 6, xs: 12, children: _jsxs(Box, { sx: { display: "flex", gap: "20px" }, className: "Lesson", children: [_jsx("img", { src: storageUrl?.replace("fileShoulebeReplace", course?.featuredImage), className: "lesson-image", alt: "" }, `img-${course.id}`), _jsxs(Box, { sx: {
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "12px",
                                                flex: "1 1 auto",
                                            }, children: [_jsx(Box, { sx: {
                                                        display: "flex",
                                                        gap: "4px",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }, children: _jsx("h6", { className: "lesson-title", children: course?.name }, `title-${course.id}`) }, `box-2-${course.id}`), _jsxs(Box, { sx: {
                                                        display: "flex",
                                                        gap: "12px",
                                                        flexWrap: "wrap",
                                                    }, children: [course?.courseItems?.map((item, index) => (_jsx("button", { className: "lesson-btn", children: _jsx("div", { className: "lesson-icon-container", style: {
                                                                    height: 32,
                                                                    width: 32,
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    backgroundColor: getCourseItemColour(item.courseItemCategory),
                                                                    borderRadius: 50,
                                                                    cursor: "pointer",
                                                                }, onClick: () => handleClickOpenIfram(item), children: item.courseItemCategory ===
                                                                    CourseItemCategory.Craft ? (_jsx("img", { src: getCourseItemIcon(item.courseItemCategory), width: 24, height: 24, alt: item.courseItemCategory.toString() })) : (_jsx(Icon, { icon: getCourseItemIcon(item.courseItemCategory), fontSize: 24, color: "#fff" })) }) }, `btn-${item.id}`))), course?.teacherCourseItems &&
                                                            course?.teacherCourseItems?.map((item, index) => (_jsx("button", { className: "lesson-btn", children: _jsx("div", { className: "lesson-icon-container", style: {
                                                                        height: 32,
                                                                        width: 32,
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        backgroundColor: "#f68735",
                                                                        borderRadius: 50,
                                                                        cursor: "pointer",
                                                                    }, onClick: () => handleClickOpenIfram(item), children: item.courseItemCategory ===
                                                                        CourseItemCategory.Craft ? (_jsx("img", { src: getCourseItemIcon(item.courseItemCategory), width: 24, height: 24, alt: item.courseItemCategory.toString() })) : (_jsx(Icon, { icon: getCourseItemIcon(item.courseItemCategory), fontSize: 24, color: "#fff" })) }) }, `btn-${item.id}`)))] }, `course-item-box-${course.id}`)] }, `box-1-${course.id}`)] }, `box-${course.id}`) }, `grid-${course.id}`))) }) })] }), _jsxs(Dialog, { fullWidth: true, open: kidsIframeModelOpen, onClose: handleIframeClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", sx: {
                    "& .MuiDialog-paper": {
                        padding: "5px",
                        maxWidth: "100%",
                    },
                }, children: [_jsxs(DialogTitle, { id: "alert-dialog-title", sx: {
                            color: "primary.main",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: isSmall ? "4px 4px" : "6px 14px",
                        }, children: ["\u00A0", _jsx(Button, { variant: "outlined", color: "primary", onClick: handleIframeClose, sx: {
                                    boxShadow: "-1.5px 1.5px 0 #F68735",
                                    color: "customDark.main",
                                    padding: "1.25px 14px",
                                    borderRadius: "6px",
                                    minWidth: "auto",
                                }, children: "X" })] }), _jsx(DialogContent, { sx: {
                            padding: isSmall ? "0px !important" : "0px 13px !important",
                            height: "auto !important",
                        }, children: _jsx("div", { children: urlType && urlType === CourseItemType.UploadAttachment ? (
                            // <DocViewer
                            //   style={{
                            //     height: lessIpad ? " 100%" : `${window.innerHeight - 140}`,
                            //   }}
                            //   config={{
                            //     header: {
                            //       overrideComponent: undefined,
                            //     },
                            //   }}
                            //   documents={[{ uri: embedlink }]}
                            //   pluginRenderers={DocViewerRenderers}
                            // />
                            // <WnPdfViewer pdfUrl={embedlink} isSmall={isSmall} windowHeight={window.innerHeight} />
                            _jsx(WnPdfViewerTwo, { pdfUrl: embedlink, isSmall: isSmall, windowHeight: window.innerHeight })) : (_jsx("iframe", { title: "course", src: embedlink, width: "100%", height: isSmall
                                    ? `${window.innerHeight - 490}`
                                    : `${window.innerHeight - 140}`, allowFullScreen: true, style: { borderRadius: "20px" }, seamless: true })) }) })] })] }));
};
export default Kid;
