import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import "./style.scss";
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { getTeacherNewsAsync } from "@application/features/teacher/teacherThunk";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { toggleIframeModelOpen } from "@application/features/teacher/teacherSlice";
const News = () => {
    const storageUrl = process.env.STORAGE_ACCOUNT;
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const { teacherNews, iframeModelOpen } = useAppSelector((state) => state.teacher);
    const [embedlink, setLink] = useState("");
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const newsImageVideoStyles = {
        minHeight: 200,
        maxHeight: 200,
        height: 200,
        width: "100%",
        objectFit: "contain",
        objectPosition: "center",
    };
    const handleIframeClose = () => {
        setLink("");
        dispatch(toggleIframeModelOpen());
    };
    useEffect(() => {
        dispatch(getTeacherNewsAsync());
    }, [dispatch]);
    const handleNewsOpenIframe = (news, type) => {
        setLink(type == 1 ? storageUrl?.replace("fileShoulebeReplace", news.picture) : news.videoLink);
        dispatch(toggleIframeModelOpen());
    };
    return (_jsxs(_Fragment, { children: [_jsx(Grid, { container: true, spacing: 4, maxWidth: "md", sx: { backgroundColor: "#fff", height: "100%", marginTop: "0" }, children: _jsx(Grid, { item: true, xs: 12, children: _jsx(Grid, { container: true, maxWidth: "lg", spacing: 4, children: _jsx(Grid, { item: true, xs: 12, children: _jsxs("div", { children: [_jsx(Typography, { sx: {
                                            fontFamily: "Marykate",
                                            fontSize: 36,
                                            mb: 2,
                                            pl: 1,
                                            color: theme.palette.primary.main,
                                        }, children: "Academie" }), _jsx(Slider, { ...settings, children: teacherNews &&
                                            teacherNews.map((news) => (_jsx("div", { children: _jsxs(Grid, { container: true, item: true, spacing: 2, children: [_jsx(Grid, { item: true, md: 6, xs: 12, sm: 12, children: _jsx(Box, { sx: { position: "relative" }, children: _jsx(Box, { component: "img", sx: {
                                                                        width: "100%",
                                                                        minHeight: "300px",
                                                                        maxHeight: "300px",
                                                                        height: "300px",
                                                                        borderRadius: "12px",
                                                                        objectFit: "contain",
                                                                        objectPosition: "center",
                                                                    }, 
                                                                    // src={`data:image/png;base64,${news.picture}`}
                                                                    src: storageUrl?.replace("fileShoulebeReplace", news.picture) }) }) }), _jsx(Grid, { item: true, md: 6, xs: 12, sm: 12, children: _jsx(Box, { sx: { position: "relative" }, className: "kids-news-hover", onClick: () => handleNewsOpenIframe(news, 2), children: _jsx("iframe", { title: news.title, src: news.videoLink, style: {
                                                                        width: "100%",
                                                                        minHeight: "300px",
                                                                        maxHeight: "300px",
                                                                        height: "300px",
                                                                        borderRadius: "12px",
                                                                    } }) }) }), _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { sx: {
                                                                        fontFamily: "Marykate",
                                                                        fontSize: 24,
                                                                        mb: 1,
                                                                        color: theme.palette.common.black,
                                                                    }, children: news.title }), _jsx(Typography, { sx: {
                                                                        color: theme.palette.common.black,
                                                                    }, variant: "body1", children: news.description })] })] }) }, news.id))) })] }) }) }) }) }), _jsxs(Dialog, { fullWidth: true, open: iframeModelOpen, onClose: handleIframeClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", sx: {
                    "& .MuiDialog-paper": {
                        padding: "5px",
                        maxWidth: "100%",
                    },
                }, children: [_jsxs(DialogTitle, { id: "alert-dialog-title", sx: {
                            color: "primary.main",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "6px 14px",
                        }, children: ["\u00A0", _jsx(Button, { variant: "outlined", color: "primary", onClick: handleIframeClose, sx: {
                                    boxShadow: "-1.5px 1.5px 0 #F68735",
                                    color: "customDark.main",
                                    padding: "1.25px 14px",
                                    borderRadius: "6px",
                                    minWidth: "auto",
                                }, children: "X" })] }), _jsx(DialogContent, { sx: {
                            padding: "0px 13px !important",
                            paddingBottom: "13px !important",
                        }, children: _jsx("div", { children: _jsx("iframe", { title: "course", src: embedlink, width: "100%", height: `${window.innerHeight - 140}`, allowFullScreen: true, style: { borderRadius: "20px" } }) }) })] })] }));
};
export default News;
