import { useEffect, useRef } from "react";
export const useWebSocket = (onMessage) => {
    const socketRef = useRef(null);
    useEffect(() => {
        const url = process.env.WEB_SOCKET_URL;
        const socket = new WebSocket(url);
        socketRef.current = socket;
        socket.onmessage = (event) => {
            onMessage(event.data);
        };
        return () => {
            socket.close();
        };
    }, [onMessage]);
};
