import { jsx as _jsx } from "react/jsx-runtime";
import { Box, useMediaQuery } from "@mui/material";
import { SpecialZoomLevel, Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./style.scss";
const WnPdfViewerTwo = ({ pdfUrl, isSmall, windowHeight, }) => {
    const isPortrait = useMediaQuery("(orientation: portrait)");
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar: (Toolbar) => _jsx(Toolbar, {}),
        sidebarTabs: () => [],
    });
    return (_jsx(Box, { sx: {
            display: "flex",
            flexDirection: "column",
            height: {
                xs: isPortrait ? "calc(100svh - 120px)" : "calc(100svh - 100px)",
                md: "calc(100svh - 120px)",
            },
        }, children: _jsx(Worker, { workerUrl: "https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js", children: _jsx(Viewer, { fileUrl: pdfUrl, plugins: [defaultLayoutPluginInstance], defaultScale: SpecialZoomLevel.PageFit }) }) }));
};
export default WnPdfViewerTwo;
