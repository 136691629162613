import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { addTeacherCourseAsync, updateTeacherCourseAsync, deleteTeacherCourseAsync, enableDisableTeacherAsync, getTeacherFaqsAsync, getTeacherNewsAsync, getTeacherSettingsAsync, enableDisableTeacherCourseAsync, getTeacherByIdAsync, getTeacherCoursesAsync, getTeachersAsync, updateTeacherAsync, enableDisableTeacherAnnualPlanCourseAsync, updateTeacherPasswordAsync } from './teacherThunk';
const initialState = {
    status: APIStatus.IDLE,
    teacherCourses: [],
    teacherNews: [],
    iframeModelOpen: false,
    iframeModelTabOpen: false,
    isCourseLoading: false
};
export const teacherSlice = createSlice({
    name: 'teacher',
    initialState,
    reducers: {
        setTeacherRequest(state, action) {
            state.teacherRequest = action.payload;
        },
        toggleIframeModelOpen(state) {
            state.iframeModelOpen = !state.iframeModelOpen;
        },
        toggleIframeModelTabOpen(state) {
            state.iframeModelTabOpen = !state.iframeModelTabOpen;
        },
        setIsCourseLoaded(state, action) {
            state.isCourseLoading = action.payload;
        },
        setTeacherCourses(state, action) {
            state.teacherCourses = action.payload;
        },
        closeIframeModelOpen(state) {
            state.iframeModelOpen = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTeachersAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getTeachersAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.teachers = action.payload;
        })
            .addCase(getTeachersAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getTeacherByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getTeacherByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.teacher = action.payload;
        })
            .addCase(getTeacherByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getTeacherSettingsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getTeacherSettingsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.teacherSetting = action.payload;
        })
            .addCase(getTeacherSettingsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getTeacherFaqsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getTeacherFaqsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.teacherFaqs = action.payload;
        })
            .addCase(getTeacherFaqsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getTeacherNewsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getTeacherNewsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.teacherNews = action.payload;
        })
            .addCase(getTeacherNewsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getTeacherCoursesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getTeacherCoursesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.teacherCourses = action.payload;
        })
            .addCase(getTeacherCoursesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addTeacherCourseAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addTeacherCourseAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addTeacherCourseAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateTeacherCourseAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateTeacherCourseAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateTeacherCourseAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateTeacherPasswordAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateTeacherPasswordAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateTeacherPasswordAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(deleteTeacherCourseAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(deleteTeacherCourseAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(deleteTeacherCourseAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateTeacherAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateTeacherAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateTeacherAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableTeacherAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableTeacherAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableTeacherAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableTeacherCourseAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableTeacherCourseAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableTeacherCourseAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableTeacherAnnualPlanCourseAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableTeacherAnnualPlanCourseAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableTeacherAnnualPlanCourseAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const { setTeacherRequest, toggleIframeModelOpen, toggleIframeModelTabOpen, setIsCourseLoaded, setTeacherCourses, closeIframeModelOpen } = teacherSlice.actions;
export default teacherSlice.reducer;
