import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis, } from "recharts";
import { Box, useMediaQuery } from "@mui/material";
const buildTooltips = (name, payload) => {
    if (name === "summerIncludedCount" || name === "summerTotalCount") {
        return {
            label: "zomer",
            toolTipText: `${payload.summerIncludedCount} / ${payload.summerTooltipCount}`,
            excludedCourses: payload.summerExcludedCourses,
            includedCourses: payload.summerIncludedCourses,
        };
    }
    else if (name === "winterIncludedCount" || name === "winterTotalCount") {
        return {
            label: "winter",
            toolTipText: `${payload.winterIncludedCount} / ${payload.winterTooltipCount}`,
            excludedCourses: payload.winterExcludedCourses,
            includedCourses: payload.winterIncludedCourses,
        };
    }
    else if (name === "autumIncludedCount" || name === "autumTotalCount") {
        return {
            label: "herfst",
            toolTipText: `${payload.autumIncludedCount} / ${payload.autumTooltipCount}`,
            excludedCourses: payload.autumExcludedCourses,
            includedCourses: payload.autumIncludedCourses,
        };
    }
    else if (name === "springIncludedCount" || name === "springTotalCount") {
        return {
            label: "lente",
            toolTipText: `${payload.springIncludedCount} / ${payload.springTooltipCount}`,
            excludedCourses: payload.springExcludedCourses,
            includedCourses: payload.springIncludedCourses,
        };
    }
    else {
    }
};
const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const result = buildTooltips(payload[0].name, payload[0].payload);
        return (_jsx("div", { style: {
                backgroundColor: "#fff",
                color: "#000",
                padding: 5,
                paddingLeft: 10,
                borderRadius: 5,
                width: 400,
            }, children: _jsxs("p", { style: { color: "#000" }, children: [_jsx("b", { children: result?.label }), " : ", result?.toolTipText, " ", _jsx("br", {}), _jsx("br", {}), result?.excludedCourses && (_jsxs(_Fragment, { children: [_jsx("b", { children: "Uitgesloten lessen" }), " : ", result?.excludedCourses, " ", _jsx("br", {})] })), result?.includedCourses && (_jsxs(_Fragment, { children: [_jsx("b", { children: "Gegeven lessen" }), " : ", result?.includedCourses] }))] }) }));
    }
    return null;
};
const CustomYAxisTick = (props) => {
    const { x, y, payload } = props;
    const breakAfterNWords = (text, n) => {
        const wordsArray = text.split(" ");
        const result = [];
        for (let i = 0; i < wordsArray.length; i += n) {
            result.push(wordsArray.slice(i, i + n).join(" "));
        }
        return result;
    };
    const brokenText = breakAfterNWords(payload.value, 5);
    return (_jsx("g", { transform: `translate(${x},${y})`, style: { width: 300 }, children: brokenText.map((line, index) => (_jsx("text", { x: 0, y: index * 15, textAnchor: "end", fill: "#666", children: _jsx("tspan", { dy: "1.2em", children: line }) }, index))) }));
};
const AnnualPlanChart = ({ data }) => {
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:500px)");
    return (_jsxs(_Fragment, { children: [_jsxs("ul", { style: {
                    listStyle: "none",
                    padding: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: isSmall ? "300px" : "100%",
                    overflowX: "auto",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none", // For Internet Explorer and Edge
                }, children: [_jsx("li", { style: { marginLeft: "25px" }, children: _jsxs("p", { style: {
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "8px",
                            }, children: [_jsx("span", { style: {
                                        width: isSmall ? "1.9375rem" : "0.9375rem",
                                        height: "0.9375rem",
                                        backgroundColor: "#F68735",
                                        marginRight: "8px",
                                    } }), _jsx("b", { style: { textAlign: "center" }, children: "herfst" })] }) }), _jsx("li", { style: { marginLeft: "25px" }, children: _jsxs("p", { style: {
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "8px",
                            }, children: [_jsx("span", { style: {
                                        width: "0.9375rem",
                                        height: "0.9375rem",
                                        backgroundColor: "#2D8AC5",
                                        marginRight: "8px",
                                    } }), _jsx("b", { style: { textAlign: "center" }, children: "winter" })] }) }), _jsx("li", { style: { marginLeft: "25px" }, children: _jsxs("p", { style: {
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "8px",
                            }, children: [_jsx("span", { style: {
                                        width: "0.9375rem",
                                        height: "0.9375rem",
                                        backgroundColor: "#FDC854",
                                        marginRight: "8px",
                                    } }), _jsx("b", { style: { textAlign: "center" }, children: "lente" })] }) }), _jsx("li", { style: { marginLeft: "25px" }, children: _jsxs("p", { style: {
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "8px",
                            }, children: [_jsx("span", { style: {
                                        width: "0.9375rem",
                                        height: "0.9375rem",
                                        backgroundColor: "#70B250",
                                        marginRight: "8px",
                                    } }), _jsx("b", { style: { textAlign: "center" }, children: "zomer" })] }) })] }), _jsx(Box, { sx: {
                    display: "block",
                    width: "100%",
                    overflowX: "auto",
                    overflowY: "hidden",
                }, children: _jsxs(BarChart, { height: 600, width: 1100, data: data, layout: "vertical", children: [_jsx(CartesianGrid, { vertical: false, strokeDasharray: "3 3" }), _jsx(XAxis, { type: "number" }), _jsx(YAxis, { dataKey: "name", type: "category", tick: _jsx(CustomYAxisTick, {}), width: 300 }), _jsx(Tooltip, { shared: false, content: _jsx(CustomTooltip, {}) }), _jsx(Bar, { dataKey: "autumIncludedCount", stackId: "a", fill: "#F68735" }), _jsx(Bar, { dataKey: "autumTotalCount", stackId: "a", fill: "#FFE4CF" }), _jsx(Bar, { dataKey: "winterIncludedCount", stackId: "b", fill: "#2D8AC5" }), _jsx(Bar, { dataKey: "winterTotalCount", stackId: "b", fill: "#87CEF3" }), _jsx(Bar, { dataKey: "springIncludedCount", stackId: "c", fill: "#FDC854" }), _jsx(Bar, { dataKey: "springTotalCount", stackId: "c", fill: "#FFE9BA" }), _jsx(Bar, { dataKey: "summerIncludedCount", stackId: "d", fill: "#70B250" }), _jsx(Bar, { dataKey: "summerTotalCount", stackId: "d", fill: "#D7FFC3" })] }) })] }));
};
export default AnnualPlanChart;
