import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, Box, Grid, TextField, useMediaQuery } from "@mui/material";
import './style.scss';
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { getGoalDropdownAsync } from "@application/features/goal/goalThunk";
import { useEffect, useState } from "react";
import { getAnnualPlanAsync } from "@application/features/annualPlan/annualPlanThunk";
import AnnualPlanChart from "../annual-plan-chart";
import GoalNotFound from "../goal-notfound";
import { APIStatus } from "@application/common/enums/api-status";
const AnnualPlan = () => {
    const [selectedGoal, setSelectedGoal] = useState(null);
    const dispatch = useAppDispatch();
    const { goalDropdownList, status } = useAppSelector((state) => state.goal);
    const { annualPlans } = useAppSelector((state) => state.annualPlan);
    useEffect(() => {
        dispatch(getGoalDropdownAsync()).then(res => {
            if (res.payload) {
                setSelectedGoal(res.payload[0]);
            }
        });
    }, [dispatch]);
    useEffect(() => {
        if (selectedGoal) {
            dispatch(getAnnualPlanAsync(selectedGoal.id));
        }
    }, [dispatch, selectedGoal]);
    const isPad = useMediaQuery("(min-width:750px) and (max-width:830px)");
    return (_jsx(_Fragment, { children: _jsx(Box, { sx: {
                marginTop: isPad ? '4rem' : "1rem",
                borderRadius: "12px",
                backgroundColor: "#fff",
                padding: "1rem",
            }, children: _jsxs(Grid, { container: true, children: [_jsx(Grid, { item: true, xs: 4 }), _jsx(Grid, { item: true, md: 4, xs: 12, children: goalDropdownList &&
                            _jsx(_Fragment, { children: _jsx(Autocomplete, { size: 'small', value: selectedGoal, onChange: (event, newValue) => {
                                        setSelectedGoal(newValue);
                                    }, options: goalDropdownList, getOptionLabel: (option) => option.label, renderInput: (params) => _jsx(TextField, { ...params, InputLabelProps: { children: '' }, label: "Kies een optie", variant: "outlined" }) }) }) }), _jsx(Grid, { item: true, xs: 4 }), _jsxs(Grid, { item: true, xs: 12, children: [(selectedGoal?.isEnabled && annualPlans) && _jsx(AnnualPlanChart, { data: annualPlans }), !selectedGoal?.isEnabled && status === APIStatus.IDLE && selectedGoal !== null &&
                                _jsx(GoalNotFound, {})] })] }) }) }));
};
export default AnnualPlan;
