import { jsx as _jsx } from "react/jsx-runtime";
import "./style.scss";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { setFeaturedImage } from "@application/features/theme/themeSlice";
import { getThemeBySeasonandTeacherAsync, enableDisableTeacherThemeAsync, } from "@application/features/theme/themeThunk";
import { getSeasonListAsync } from "@application/features/season/seasonThunk";
function Sidebar() {
    const [loading, setLoading] = useState(false);
    const { themeList } = useAppSelector((state) => state.theme);
    const { currentUser } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { seasonId } = useParams();
    useEffect(() => {
        if (seasonId) {
            const dto = {
                teacherId: currentUser?.id ?? "",
                seasonId: seasonId,
            };
            dispatch(getThemeBySeasonandTeacherAsync(dto));
            setLoading(true);
        }
    }, [dispatch, seasonId, !loading]);
    const label = { inputProps: { "aria-label": "Switch demo" } };
    const switchPosition = {
        position: "absolute",
        zIndex: 1,
        right: -12,
        top: -12,
    };
    return (_jsx("div", { className: "wraper", children: _jsx("ul", { className: "side-tabs", children: themeList &&
                currentUser?.role &&
                themeList.map((item, index) => {
                    return ((currentUser.role == "Teacher" || item.isEnabledByTeacher) && (_jsx("li", { className: item.isEnabledByTeacher ? "" : "disabled", children: _jsx("p", { className: "link", onClick: async () => {
                                dispatch(setFeaturedImage(item.featuredImage));
                                const enableDisableTeacherThemeDto = {
                                    teacherId: currentUser?.id ?? "",
                                    seasonId: seasonId ?? "",
                                    themeId: item.id ?? "",
                                };
                                if (!item.isEnabledByTeacher) {
                                    await dispatch(enableDisableTeacherThemeAsync(enableDisableTeacherThemeDto));
                                    await dispatch(getSeasonListAsync(enableDisableTeacherThemeDto.teacherId));
                                }
                                navigate(`/${currentUser?.role.toLocaleLowerCase()}/courses/${item.seasonId}/${item.id}`);
                                setLoading(false);
                            }, children: item.name }, `p-${index}`) }, `li-${index}`)));
                }) }) }));
}
export default Sidebar;
