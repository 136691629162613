import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ThemeProvider } from "@emotion/react";
import "./style.scss";
import WinterLeaves from "../../../../public/images/login-cartoon.png";
import Logo from "../../../../public/images/logo.png";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { CssBaseline, Link as MUILink } from "@mui/material";
import { useEffect, useState } from "react";
import { resetApiError } from "@application/features/auth/authSlice";
import { useAppDispatch } from "@application/store/useStore";
const customColors = {
    winter: {
        main: "#2D8AC5",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#fff",
    },
    inputBg: {
        main: "#F7F7F7",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#000",
    },
    customDark: {
        main: "#343233",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#fff",
    },
    white: {
        main: "#fff",
        light: "#fff",
        dark: "#fff",
        contrastText: "#000",
    },
};
const AuthLayout = () => {
    const theme = createTheme({
        palette: {
            primary: {
                main: "#2D8AC5",
                light: "#42a5f5",
                dark: "#1565c0",
                contrastText: "#fff",
            },
            winter: {
                main: customColors.winter.main,
                light: customColors.winter.light,
                dark: customColors.winter.dark,
                contrastText: customColors.winter.contrastText,
            },
            inputBg: {
                main: customColors.inputBg.main,
                light: customColors.inputBg.light,
                dark: customColors.inputBg.dark,
                contrastText: customColors.inputBg.contrastText,
            },
            customDark: {
                main: customColors.customDark.main,
                light: customColors.customDark.light,
                dark: customColors.customDark.dark,
                contrastText: customColors.customDark.contrastText,
            },
            white: {
                main: customColors.white.main,
                light: customColors.white.light,
                dark: customColors.white.dark,
                contrastText: customColors.white.contrastText,
            },
            secondary: {
                main: "#ffba00",
            },
        },
        shape: {
            borderRadius: 20,
        },
        typography: {
            fontFamily: '"Quicksand", sans-serif',
            button: {
                fontSize: "1rem",
                fontWeight: "normal",
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        "&:hover": {
                            backgroundColor: "winter.main",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        input: {
                            padding: "10px 14px",
                        },
                        "&.login-field": {
                            borderRadius: 14,
                            border: "none",
                            backgroundColor: "#fff",
                            padding: "6px 14px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5)",
                            ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                        },
                    },
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        "&.login-field": {
                            ".MuiInputBase-root": {
                                borderRadius: 14,
                                border: "none",
                                backgroundColor: "#fff",
                                padding: "6px 14px",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5)",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                                border: "none !important",
                            },
                        },
                    },
                },
            },
        },
    });
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [currentLogin, setCurrentLogin] = useState("");
    useEffect(() => {
        switch (location.pathname) {
            case "/teacher-login":
                setCurrentLogin("teacher-log");
                break;
            case "/student-login":
                setCurrentLogin("student-log");
                break;
            case "/coordinator-login":
                setCurrentLogin("coord-log");
                break;
            case "/forget-password":
                setCurrentLogin("forget-password");
                break;
            default:
                break;
        }
    }, [location.pathname]);
    return (_jsxs(ThemeProvider, { theme: theme, children: [_jsx(CssBaseline, {}), _jsxs("div", { className: `teacher-main ${currentLogin}`, children: [_jsx("img", { src: WinterLeaves, className: "winter-leaves", alt: "winter" }), _jsxs("div", { className: "teacher-inner", children: [_jsxs("div", { className: "login-tabs", children: [_jsx(MUILink, { className: "teacher-log", component: "button", variant: "body2", onClick: () => {
                                            setCurrentLogin("teacher-log");
                                            dispatch(resetApiError());
                                            navigate("/teacher-login");
                                        }, children: "leerkracht" }), _jsx(MUILink, { className: "coord-log", component: "button", variant: "body2", onClick: () => {
                                            setCurrentLogin("coordinator-log");
                                            dispatch(resetApiError());
                                            navigate("/coordinator-login");
                                        }, children: "coordinator" }), _jsxs(MUILink, { className: "forget-password", component: "button", variant: "body2", onClick: () => {
                                            setCurrentLogin("forget-password");
                                            dispatch(resetApiError());
                                            navigate("/forget-password");
                                        }, children: ["wachtwoord ", _jsx("br", {}), " vergeten"] }), _jsx(MUILink, { className: "student-log", component: "button", variant: "body2", onClick: () => {
                                            setCurrentLogin("student-log");
                                            dispatch(resetApiError());
                                            navigate("/student-login");
                                        }, children: "groep" })] }), _jsx("img", { src: Logo, alt: "logo", height: 50, className: "logo-auth" }), _jsx("div", { className: "marginTopForOrientation", children: _jsx(Outlet, {}) })] })] })] }));
};
export default AuthLayout;
