import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import "./App.scss";
import i18n from './i18n';
import { ToastContainer } from "react-toastify";
import Loading from "./ui/components/wn-loader";
import { resetStateByPathName } from "./application/common/helpers/state-helper";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./application/store/useStore";
import AppRoutes from "./ui/containers/layout/AppRoutes";
import { logout } from "./application/features/auth/authSlice";
import 'react-toastify/dist/ReactToastify.css';
import { useWebSocket } from "@application/common/helpers/web-socket-helper";
import LocalStorageService from "@infrastructure/local-storage-service";
import { getCurrentUserFromJwt } from "@application/common/helpers/jwt-helper";
function App() {
    const dispatch = useAppDispatch();
    const { isLoading } = useAppSelector((state) => state.loading);
    const { isCourseLoading } = useAppSelector((state) => state.teacher);
    //const { currentUser } = useAppSelector((state: RootState) => state.auth);
    const location = useLocation();
    useEffect(() => {
        moment.locale(i18n.language);
    }, []);
    useEffect(() => {
        const onStorageChange = (event) => {
            if (event.key === 'AUTH_TOKEN' && event.newValue === null)
                dispatch(logout());
        };
        window.addEventListener('storage', onStorageChange);
        return () => {
            window.removeEventListener('storage', onStorageChange);
        };
    }, [dispatch]);
    //   useEffect(() => {
    //     const iframe = document.getElementById('wacframe');
    //     iframe?.addEventListener('load', () => {
    //     // Access the contentDocument of the iframe
    //     const iframeContent = iframe?.contentDocument || iframe?.contentWindow.document;
    //     // Find and hide the element with id "toolbarViewerRight"
    //     const toolbarViewerRight = iframeContent.getElementById('toolbarViewerRight');
    //     if (toolbarViewerRight) {
    //         toolbarViewerRight.style.display = 'none';
    //     }
    //     });
    //   }, []);
    useEffect(() => {
        return () => {
            resetStateByPathName(location.pathname);
        };
    }, [location.pathname]);
    useWebSocket((message) => {
        const token = LocalStorageService.getAccessToken();
        if (token) {
            const currentUser = getCurrentUserFromJwt(token);
            if (message === currentUser?.id) {
                dispatch(logout());
            }
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(ToastContainer, { position: 'top-right', autoClose: 1000, newestOnTop: true, pauseOnHover: true, theme: 'colored' }), isCourseLoading && _jsx(Loading, { isLoading: isCourseLoading }), isLoading && _jsx(Loading, { isLoading: isLoading }), _jsx("div", { className: 'boxed_wrapper', children: _jsx(AppRoutes, {}) })] }));
}
export default App;
